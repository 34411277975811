
























import { defineComponent } from '@vue/composition-api';
import { Column } from '@/features/ui/table/model';
import { Order, SpotHealth } from '@/types/iot-portal';
import {
  GatewaySpots_gateways_first_deviceMounts_items,
  GatewaySpots_gateways_first_deviceMounts_items_spot,
} from '@/features/core/components/connected-devices/__generated__/GatewaySpots';
import queryGet from '@/features/core/components/connected-devices/connected-device-parent.gql';
import {
  AppManagerGatewaySpotsLastMetricsQuery_spots_items,
  AppManagerGatewaySpotsLastMetricsQuery_spots_items_metrics,
} from '@/features/core/components/connected-devices/__generated__/AppManagerGatewaySpotsLastMetricsQuery';
import gatewaySpotsLastMetricsQuery from '@/features/core/components/connected-devices/gateway-spots-last-metrics.gql';
import DeviceRoleMapMixin from '../mixins/device-role-map';
import { Metrics } from '../../util/metrics';
import { SPOT_HEALTH_META } from '@/features/domain-ui/spot-health/constants';

interface ConnectedDevicesProps {
  gatewayId: string;
}

type SpotInfo = GatewaySpots_gateways_first_deviceMounts_items_spot & {
  spotHealth: string | undefined;
  latestMetricTime: string | undefined;
};

type SpotMetric = AppManagerGatewaySpotsLastMetricsQuery_spots_items_metrics;

type SpotItem = AppManagerGatewaySpotsLastMetricsQuery_spots_items;

type DeviceMountItem = GatewaySpots_gateways_first_deviceMounts_items;

const LAST_METRIC_STARTING_TIME_OFFSET = '-P30D';

export default defineComponent<ConnectedDevicesProps, Record<string, unknown>, any, any>({
  components: {},
  mixins: [DeviceRoleMapMixin],
  props: {
    treeNodeId: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      columns: [
        { name: 'path', label: 'Location', width: 150 },
        { name: 'name', label: 'Name', width: 150 },
        { name: 'spotHealth', label: 'Health', width: 150 },
        { name: 'latestMetricTime', label: 'Last Metric Time', width: 150 },
      ] as Column[],
      sortKeys: [
        {
          key: 'name',
          direction: Order.ASC,
        },
      ],
      loading: true,
      connectedDevices: [],
      // pagination
      currentPage: 1,
      totalPages: null,
      pageSize: 10,
    };
  },
  computed: {},
  async created() {
    await this.loadConnectedTreeNodes();
  },
  methods: {
    async loadConnectedTreeNodes() {
      try {
        // Get the ancestors to get the object
        const { data } = await this.$apollo.query({
          query: queryGet,
          fetchPolicy: 'network-only',
          variables: { id: this.treeNodeId },
        });
        if (data?.gateways?.first?.deviceMounts?.items) {
          const spotIds: string[] = data?.gateways?.first?.deviceMounts?.items.map(
            (item: DeviceMountItem) => item.spot.id,
          );

          const lastMetrics = await this.$apollo.query({
            query: gatewaySpotsLastMetricsQuery,
            fetchPolicy: 'network-only',
            variables: {
              ids: spotIds,
              descriptors: [
                {
                  start: LAST_METRIC_STARTING_TIME_OFFSET,
                  stop: new Date(),
                },
              ],
            },
          });

          this.connectedDevices = data?.gateways?.first?.deviceMounts?.items.map((item: DeviceMountItem) => {
            return this.createSpotInfo(item, lastMetrics.data?.spots?.items);
          });
        }
        // Get the count
        if (data?.gateways?.first?.deviceMounts?.count) {
          this.totalPages = Math.ceil(data?.gateways?.first?.deviceMounts?.count / this.pageSize);
        }
        this.loading = false;
      } catch (error) {
        console.log('error', error);
      }
    },

    createSpotInfo(item: DeviceMountItem, spots: SpotItem[]): SpotInfo {
      const spotMetricInfo: SpotItem | undefined = spots.find((spot: SpotItem) => spot.id == item.spot.id);

      const primaryMetric: SpotMetric | undefined = this.getPrimaryMetric(spotMetricInfo);

      const metric: Metrics | undefined = primaryMetric?.latest ? Metrics.create([primaryMetric.latest]) : undefined;

      const spotHealthDescription = spotMetricInfo?.health ? SPOT_HEALTH_META[spotMetricInfo?.health].label : undefined;
      const latestMetricTime = metric?.latestTime ? new Date(metric.latestTime).toLocaleString() : undefined;

      return { ...item.spot, spotHealth: spotHealthDescription, latestMetricTime: latestMetricTime };
    },

    getPrimaryMetric(spotMetricInfo: SpotItem): SpotMetric | undefined {
      const primaryMetric = spotMetricInfo.metrics.find(
        (metric: SpotMetric) => metric.latest.name == this.primaryMetricName(spotMetricInfo.role),
      );

      return primaryMetric;
    },

    primaryMetricName(spotRole: string): string | undefined {
      return this.deviceRoleMap[spotRole]?.defaultMetricNames?.[0];
    },
  },
});
